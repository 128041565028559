'use strict';

var getPolyfill = require('./polyfill');
var define = require('define-properties');

module.exports = function shimPadEnd() {
	var polyfill = getPolyfill();
	define(String.prototype, { padEnd: polyfill }, {
		padEnd: function testPadEnd() {
			return String.prototype.padEnd !== polyfill;
		}
	});
	return polyfill;
};
